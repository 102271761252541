import React from 'react';
import { Trans } from 'react-i18next';

import { LinkWrapper, Text } from '@components';
import { Button } from '@core';
import { getDownloadLinkByDevice } from '@shared/app-link';

import { PAGE_DATA as DEALS_PAGE_DATA } from './deals';

const TextLinkWrapper = ({ children, link, target, rel }) => (
  <LinkWrapper link={link} target={target} rel={rel}>
    <Button type="button" variant="text">
      <Text.Body4Strong $fontSize="inherit" as="span" color="inherit">
        {children}
      </Text.Body4Strong>
    </Button>
  </LinkWrapper>
);

export const PAGE_DATA = (props) => ({
  ...DEALS_PAGE_DATA(props),
  headerCta: {
    ...DEALS_PAGE_DATA(props).headerCta,
    text: (textProps) => ({
      ...DEALS_PAGE_DATA(props).headerCta.text(textProps),
      infoText: (
        <Trans
          i18nKey="deals:header.infoDownloadText"
          components={[
            <TextLinkWrapper
              key={0}
              link={getDownloadLinkByDevice()}
              target="_blank"
              rel="noopener noreferrer"
            />,
          ]}
        />
      ),
    }),
  },
});
